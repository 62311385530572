
































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import lodash from "lodash";
import table from "@/utils/table";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue";

import DetailRow from "@/components/Shared/Table/DetailRow.vue";
import RowFilter from "@/components/Shared/Table/RowFilter.vue";

import DetailField from "@/components/Shared/Table/DetailField.vue";
import CategoryField from "@/components/Shared/Table/CategoryField.vue";
import EditField from "@/components/Shared/Table/EditField.vue";
import SwitchField from "@/components/Shared/Table/SwitchField.vue";

Vue.component("my-detail-row", DetailRow);
Vue.component("vuetable-field-checkbox", VuetableFieldCheckbox);

type SortOrder = {
  field: string;
  direction: string;
  sortField: string;
};

@Component({
  components: {
    VuetablePagination,
    VuetableRowHeader,
    RowFilter,
    DetailField,
    CategoryField,
    EditField,
    SwitchField,
  },
})
export default class Table extends Vue {
  @Prop({ required: true }) public columns!: Array<Object>;
  @Prop() public addLabel!: String;
  @Prop() public saveLabel!: String;
  @Prop() public modifiedData!: Array<Object>;
  @Prop({ required: true }) public url!: string;
  @Prop({ required: true }) public trackBy!: string;
  @Prop() public searchBar!: Boolean;
  @Prop() public checkedRows!: Array<Object>;
  private selectedRows: Array<Object> = [];
  public myDetailRow: object = DetailRow;
  private updatedRows: Array<Object> = [];
  private sortOrder: Array<Object> = [];
  private perPage: number = 10;
  //private hasBeenModified: Boolean = false;

  public save(): void {
    this.$emit("save-data");
    this.$store.commit("changeHasBeenModified", false);
    this.refresh();
  }

  public add(): void {
    this.$emit("add-data");
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  public refresh(): void {
    (<any>this.$refs.vuetable).refresh();
  }

  public onPaginationData(paginationData: any): void {
    (<any>this.$refs.pagination).setPaginationData(paginationData);
  }
  private onChangePage(page: any): void {
    (<any>this.$refs.vuetable).changePage(page);
  }
  private getrawData(obj: any, path: string): any {
    return table.findByPath(obj, path);
  }
  /**
   * modify
   * Triggered when a cell has been modified
   */
  public modify(props: any) {
    const path = (<any>this.$refs.vuetable).trackBy;
    this.$store.commit("changeHasBeenModified", true);
    const propToSearch = table.findByPath(props.rowData, path);

    let index = this.modifiedData.findIndex(function(item, index) {
      return propToSearch === table.findByPath(item, path);
    });
    if (index > -1) {
      this.modifiedData.splice(index, 1);
    }
    this.modifiedData.push(props.rowData);
  }

  /**
   * Sort rows based on sortOrder
   * Update the pagination
   *
   * @param {array} sortOrder sortOrder that has been changed
   * @param {any} pagination global sorting order
   * @return {object} sorted rows, pagination
   */
  private dataManager(sortOrder: Array<SortOrder>, pagination: any) {
    if (this.updatedRows.length < 1) return;

    let local = this.updatedRows;

    if (sortOrder.length > 0) {
      local = lodash.orderBy(
        local,
        this.sortOrder.map((order) => (<SortOrder>order).sortField),
        this.sortOrder.map((order) => (<SortOrder>order).direction)
      );
    }

    pagination = (<any>this.$refs.vuetable).makePagination(
      local.length,
      this.perPage
    );

    let from = pagination.from - 1;
    let to = from + this.perPage;

    return {
      pagination: pagination,
      data: lodash.slice(local, from, to),
    };
  }

  /**
   * Update the rows based on a search value
   */
  /*
        private search(): void {
            const filter = (<HTMLInputElement>this.$refs.input).value.toUpperCase();
            this.updatedRows = [];
            this.rows.forEach(row => {
                for(let value of Object.values(row)) {
                    if(value.toString().toUpperCase().indexOf(filter) > -1) {
                        this.updatedRows.push(row);
                        break;
                    }
                }
            });
            this.refresh();
        }*/

  @Watch("updatedRows")
  onPropertyChanged(value: Array<Object>, oldValue: Array<Object>) {
    this.refresh();
  }
  @Watch("selectedRows")
  onselectedRowsChanged(value: Array<Object>, oldValue: Array<Object>) {
    this.$store.commit("changeHasBeenModified", true);
    this.$emit("rowsSelectionChange", value);
  }
  @Watch("checkedRows")
  oncheckedRowsChange(value: Array<Object>, oldValue: Array<Object>) {
    this.selectedRows = value;
  }
  /**
   * Update rows based on filter if filters are allowed
   * TODO :
   * filter function
   */
  /*
        public onRowHeaderEvent(type: any, payload: any): any {
            this.updatedRows = [];
            if(type == 'filter' && payload.length > 0) {
                this.rows.forEach(row => {
                    let value = (<any>row)[payload[0].key];
                    if(Array.isArray(value)) {
                        for(let iter of value) {
                            console.log(iter.toString().toUpperCase(), iter)
                            if(iter.toString().toUpperCase().indexOf(payload[0].value.toString().toUpperCase()) > -1) {
                                this.updatedRows.push(row);
                                break;
                            }
                        }
                    } else {
                        if(value.toString().toUpperCase().indexOf(payload[0].value) > -1) {
                            this.updatedRows.push(row);
                        }
                    }
                });
            }
            this.refresh();
            
            console.log("onRowHeaderEvent:", type, payload);

            let handler = {
                filter: (app, vuetable, payload) => {
                    let str = payload.map((f) => `filters[]=${f.key}|${f.value}`).join('&')
                    console.log('filter:', payload, str)
                }
            }

            return typeof handler[type] === "function"
                ? handler[type](this, this.$refs.vuetable, payload)
                : console.log("Unhandled event: ", type, payload);
            
        }*/

  public mounted() {}
}
